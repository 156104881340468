import React, { useState } from "react";
import styled from "styled-components";
import colors from "../colors";
import LayoutBlock from "./LayoutBlock";

const LayoutSectionWrapper = styled.div`
  ${LayoutBlock};

  position: relative;
  overflow: ${props => (props.overflow ? props.overflow : "")};

  flex: 1 1 auto;

  @media (max-width: 960px) {
    overflow: hidden;
  }

  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: ${props => (!props.bgColor ? "transparent" : colors[props.bgColor])};
    ${props =>
      props.skew &&
      `
      transform: skewY(${props.skew});
    `}
    ${props =>
      props.mask &&
      `
      mask: url(/masks/${props.mask}.svg);
    `}
  }

  > svg {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  padding-top: 3em;
  padding-bottom: 3em;
  display: flex;
  min-height: ${props => props.height};

  ${props =>
    props.centerAt &&
    `
      @media (max-width: ${props.centerAt}) {
        *, h1, h2, h3, p {
          text-align: center;
        }
        h1, h2, h3, p {
          margin-left: auto;
          margin-right: auto;
        }
      }
    `};

  ${props =>
    props.center
      ? `
    align-items: center;
    justify-content: center;
    text-align: center;
    h1, h2, h3, h4, p, a {
      margin-right: auto;
      margin-left: auto;
      text-align: center;
    }
  `
      : ""}
  :last-child {
    flex: 1 1 auto;
  }

  > div {
    ${props =>
      props.small &&
      `
      width: 54em;
    `}
  }
`;

const LayoutSection = props => {
  return (
    <>
      <LayoutSectionWrapper {...props}>
        <div style={{ width: props.width }}>{props.children}</div>
      </LayoutSectionWrapper>
    </>
  );
};

export default LayoutSection;
