import React from "react";
import styled from "styled-components";
import P1 from "./styled/P1";
import colors from "./colors";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1em;
  svg {
    width: 1.8em;
    height: 1.8em;
    margin-right: 0.7em;
  }
  p {
    margin: 0;
  }
  a {
    text-decoration: none;
    color: red;
  }
`;

const authors = {
  fabrizio: {
    name: "Fabrizio",
    url: "https://twitter.com/linuz90"
    // avatar: images.fabSmall
  },
  francesco: {
    name: "Francesco",
    url: "https://twitter.com/frankdilo"
    // avatar: images.frankSmall
  }
};

const PostAuthor = props => {
  const { author, date } = props.frontmatter;
  const { url, name, avatar } = authors[author];

  return (
    <Wrapper>
      {/* avatar */}
      <P1>
        <a href={url}>{name}</a> – {date}
      </P1>
    </Wrapper>
  );
};

export default PostAuthor;
