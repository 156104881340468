import { graphql } from "gatsby";
import React, { useState } from "react";
import BlogSubheader from "../components/BlogSubheader";
import CookieBanner from "../components/CookieBanner";
import Layout from "../components/layout";
import LayoutSection from "../components/layout/LayoutSection";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import PostPreview from "../components/PostPreview";
import SEO from "../components/seo";

const BlogPage = props => {
  const {
    data: {
      allMarkdownRemark: { edges }
    }
  } = props;

  const [subscribePopupShown, setSubscribePopupShown] = useState(false);

  return (
    <Layout>
      <SEO
        title="Unreadit Blog"
        description="Product announcements, interviews and in-depth articles about Unreadit and the topics we cover on our newsletters"
        canonical={props.location.href}
      />
      <BlogSubheader />
      <CookieBanner />
      <LayoutWrapper>
        <LayoutSection small>
          {edges.map(edge => (
            <PostPreview key={edge.node.id} post={edge.node} />
          ))}
        </LayoutSection>
      </LayoutWrapper>
    </Layout>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: { frontmatter: { author: { ne: null } } }, sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 280, format: HTML)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            author
          }
        }
      }
    }
  }
`;
