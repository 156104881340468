import React from "react";
import styled from "styled-components";
import Button from "../components/button";
import H1 from "./styled/H1";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60em;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 1.5em;
  @media (max-width: 640px) {
    padding-right: 1.8em;
    padding-left: 1.8em;
    a {
      padding: 0.5em 0.7em;
      p {
        font-size: 0;
        svg {
          font-size: 1rem;
          margin: 0;
        }
      }
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > * {
    margin-left: 1em;
  }
`;

const BlogSubheader = props => {
  return (
    <Wrapper>
      <H1 left style={{ margin: 0, fontSize: "1.4em", opacity: 0.001 }}>
        Unreadit Blog
      </H1>
    </Wrapper>
  );
};

export default BlogSubheader;
