import React from "react";
import styled from "styled-components";

const LayoutWrapperWrapper = styled.div`
  /* min-height: 100vh; */
  max-width: 100vw;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
`;

const LayoutWrapper = props => {
  return <LayoutWrapperWrapper {...props}>{props.children}</LayoutWrapperWrapper>;
};

export default LayoutWrapper;
