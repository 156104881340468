import React from "react";
import styled from "styled-components";
import colors from "./colors";

const BlogPostBody = styled.div`
  text-align: left;
  font-size: 1.15em;
  .gatsby-resp-image-link {
    border-bottom: none !important;
    position: relative;
  }
  > p > .gatsby-resp-image-wrapper,
  > .gatsby-resp-image-figure,
  > p .gatsby-resp-image-figure,
  > .gatsby-resp-image-link,
  > p .gatsby-resp-image-link,
  > p > img {
    margin: 2em auto !important;
  }
  > p > .gatsby-resp-image-wrapper,
  > .gatsby-resp-image-figure,
  > p .gatsby-resp-image-figure,
  > .gatsby-resp-image-link,
  > p .gatsby-resp-image-link {
    @media (min-width: 1000px) {
      margin: 2em -3em !important;
    }
  }
  p,
  ul,
  ol,
  blockquote {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

    color: ${colors.ultra_dark};
    margin: 0.3em 0 0.8em;
    font-weight: 400;
    line-height: 1.8;
  }
  h2 {
    font-size: 2em;
  }
  h3 {
    font-size: 1.6em;
  }
  h4 {
    font-size: 1.2em;
  }
  h2,
  h3,
  h4 {
    color: ${colors.ultra_dark};
    margin-top: 1.2em;
    margin-bottom: 0.5em;
    text-align: left;
  }
  p > strong > em {
    font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
    font-style: normal;
    font-weight: 600;
  }
  code {
    font-weight: 500;
    color: ${colors.accent2};
    background-color: rgba(208, 31, 25, 0.08);
    font-size: 1.1em;
    padding: 0.2em 0.5em;
    border-radius: 0.3em;
  }
  blockquote {
    padding-left: 1.5em;
    padding-right: 1.5em;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    font-style: italic;
    > * {
      color: ${colors.darker};
    }
  }
  hr {
    border: none;
    border-bottom: 1px solid ${colors.light};
    margin: 3em auto;
    width: 100%;
    max-width: 14em;
  }
  a {
    text-decoration: none;
    border-bottom: 1.5px solid ${colors.grey};
    color: ${colors.accent1};
    font-weight: 700;
    :hover {
      border-bottom: 1.5px solid ${colors.dark};
    }
  }
  figcaption {
    font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 0.9em;
    color: ${colors.dark};
    text-align: center;
    padding-top: 1em;
  }
  img {
    max-width: 100%;
    display: block;
  }

  .contribution-header {
    p > em {
      font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
        "Open Sans", "Helvetica Neue", sans-serif;
      font-style: normal;
      color: ${colors.grey};
      a {
        color: inherit;
        font-weight: 400;
        text-decoration: underline;
      }
    }
    .gatsby-resp-image-link {
      border-radius: 50%;
      overflow: hidden;
      object-fit: cover;
      width: 2.5em;
      display: inline-block;
      margin: 0;
    }

    p:first-child {
      margin: 0;
      display: inline-block;
    }
    h2 {
      margin: 0 0 0 0.3em;
      position: relative;
      bottom: 0.25em;
      display: inline-block;
      a {
        font-weight: 400;
        color: ${colors.ultra_dark};
        :hover {
          text-decoration: none;
          color: ${colors.accent1};
        }
      }
    }
    @media (max-width: 700px) {
      font-size: 0.8em;
    }
  }
`;

export default BlogPostBody;
