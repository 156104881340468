import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import colors from "./colors";
import styled, { keyframes } from "styled-components";
import P1 from "./styled/P1";
import svgSprites from "./svgSprites";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
`;

const BannerWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 0 1em 1em;
  right: 0;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 30;
  animation: ${fadeIn} 0.2s 0.5s backwards;

  ${props =>
    !props.show &&
    `
    display: none;
  `}

  >div {
    padding: 0.4em 0.8em;
    border-radius: 0.3em;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid rgba(0, 0, 0, 0.3);
    // color: white;
    font-size: 0.9em;
    a {
      text-decoration: underline;
    }
    p {
      margin: 0;
      font-size: 0.9em;
    }
  }
`;

const Close = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 1em;
    height: 1em;
    display: inline-block;
    margin-left: 0.7em;
    opacity: 0.8;
    cursor: pointer;
    path {
      fill: ${colors.ultra_dark};
    }
    &:hover {
      opacity: 1;
    }
  }
`;

const CookieBanner = () => {
  const [cookieDismissed, setCookieDismissed] = useState(false);

  // if (cookieDismissed) return null;

  useEffect(() => {
    if (localStorage.getItem("cookieDismissed")) {
      setCookieDismissed(true);
    }
  }, []);

  useEffect(() => {
    if (cookieDismissed) {
      localStorage.setItem("cookieDismissed", true);
    }
  }, [cookieDismissed]);

  return (
    <BannerWrapper show={!cookieDismissed}>
      <div>
        <P1>
          {cookieDismissed} By using this website you accept our <Link to="/privacy">Privacy Policy</Link> and{" "}
          <Link to="/cookie">Cookie Policy</Link>.
        </P1>
        <Close
          onClick={() => {
            setCookieDismissed(true);
          }}
        >
          {svgSprites.glyphs.close2}
        </Close>
      </div>
    </BannerWrapper>
  );
};

export default CookieBanner;
