import { Link, navigate } from "gatsby";
import React from "react";
import styled from "styled-components";
import Button from "./button";
import PostAuthor from "./PostAuthor";
import H2 from "./styled/H2";
import colors from "./colors";
import BlogPostBody from "./BlogPostBody";

const PostPreviewWrapper = styled.div`
  margin-bottom: 6em;
`;

const PostPreview = ({ post }) => {
  return (
    <PostPreviewWrapper>
      <PostAuthor frontmatter={post.frontmatter} />
      <H2 left huge gradient>
        <Link to={`${post.frontmatter.path}/`}>{post.frontmatter.title}</Link>
      </H2>
      <BlogPostBody dangerouslySetInnerHTML={{ __html: post.excerpt }} />
      <Button label="Keep reading..." tertiary small to={`${post.frontmatter.path}/`} style={{ marginTop: ".7em" }} />
    </PostPreviewWrapper>
  );
};

export default PostPreview;
